<template>
  <div class="email-input-form">
    <div class="heading">
      <div class="text">メールアドレス</div>
      <div class="required" v-if="requiredFlg">必須</div>
    </div>
    <div class="body">
      <validation-provider
        :rules="{ emailRule: true, requiredRule: requiredFlg }"
        v-slot="{ errors }"
        class="validate"
      >
        <base-text-input-mail
          :value="value"
          :disabledFlg="unchangableMailFlg"
          v-model="input"
        />
        <div class="error" data-test="email-err">{{ errors[0] }}</div>
      </validation-provider>
    </div>
  </div>
</template>

<script>
import BaseTextInputMail from '@/components/parts/atoms/BaseTextInputMail'
import { ValidationProvider } from 'vee-validate'
import '@/utils/validation_rules'

export default {
  name: 'EmailInputForm',

  components: {
    BaseTextInputMail,
    ValidationProvider
  },

  props: {
    value: { type: String },
    requiredFlg: { type: Boolean },
    unchangableMailFlg: { type: Boolean, default: false }
  },

  computed: {
    input: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.email-input-form {
  display: flex;
  > .heading {
    display: flex;
    align-items: center;
    width: 40%;
    height: 33px;
    font-size: 15px;
    > .required {
      padding-left: 10px;
      font-size: 13px;
      color: #{$tomato};
    }
  }
  > .body {
    width: 60%;
    @include validate-message();
  }
}
</style>
