<template>
  <div class="base-text-input-mail">
    <input
      type="email"
      placeholder="yourmail@mail.com"
      class="base-text-input-mail-class"
      data-e2e="base-text-input-mail"
      maxlength="254"
      :value="value"
      @input="inputText"
      :disabled="disabledFlg"
    />
  </div>
</template>

<script>
export default {
  name: 'BaseTextInputMail',

  props: {
    value: { type: String },
    disabledFlg: { type: Boolean, default: false }
  },

  methods: {
    inputText(e) {
      this.$emit('input', e.target.value)
    }
  }
}
</script>

<style lang="scss" scoped>
.base-text-input-mail {
  > .base-text-input-mail-class {
    font-size: 15px;
    height: 33px;
    border-radius: 5px;
    border: solid 2px #{$light-grey};
    background-color: #{$white};
    padding: 2px 11px;
    box-sizing: border-box;
    &::placeholder {
      text-align: left;
    }
    &:disabled {
      background-color: #{$light-grey};
    }
  }
}
</style>
