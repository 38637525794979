<template>
  <div class="staff-input-form">
    <full-name-input-form
      class="row full-name-input-form"
      :lastName="staff.lastName"
      :firstName="staff.firstName"
      :requiredFlg="true"
      @input-last-name="inputLastName"
      @input-first-name="inputFirstName"
      >スタッフ名（全角）
    </full-name-input-form>
    <select-box-form
      class="row select-box-form"
      :requiredFlg="true"
      :selectData="jobs"
      v-model="staff.jobId"
    >
      職業
    </select-box-form>
    <select-box-form
      class="row select-box-form"
      :selectData="shiftPatterns"
      :note="
        '週シフトを設定すると勤務表画面にてシフトを一括登録すことができます。'
      "
      v-model="staff.shiftPatternId"
    >
      週シフト
    </select-box-form>
    <div class="row check-box" v-if="isStaffNewPage">
      <div class="heading">
        <div class="text">シフト自動登録</div>
        <hint-balloon
          :note="'週シフトに沿って２ヶ月分の勤務表を自動で登録します。'"
        />
      </div>
      <div class="field">
        <base-check-box
          :isChecked="staffWorkingDatesFlg"
          @input="toggleStaffWorkingDatesFlg"
          :disabled="staff.shiftPatternId === 0"
        />
      </div>
    </div>
    <div class="row login-setting">
      <div class="heading">ログイン設定</div>
      <div class="radio-button">
        <base-radio-button
          v-for="datum in radioButtonData"
          :key="datum.id"
          :option="datum"
          v-model="staff.loginFlg"
        />
      </div>
    </div>
    <email-input-form
      class="row"
      v-model="staff.email"
      :requiredFlg="checkLoginFlg"
      :unchangableMailFlg="unchangableMailFlg"
    ></email-input-form>
    <color-input-form
      class="row"
      :requiredFlg="true"
      :color="staff.color"
      :note="colorDescription"
      v-model="staff.color"
      >カラー</color-input-form
    >
  </div>
</template>

<script>
import FullNameInputForm from '@/components/parts/molecules/FullNameInputForm'
import SelectBoxForm from '@/components/parts/molecules/SelectBoxForm'
import BaseRadioButton from '@/components/parts/atoms/BaseRadioButton'
import EmailInputForm from '@/components/parts/molecules/EmailInputForm'
import { mapGetters } from 'vuex'
import BaseCheckBox from '@/components/parts/atoms/BaseCheckBox'
import HintBalloon from '@/components/parts/atoms/HintBalloon'
import ColorInputForm from '@/components/parts/molecules/ColorInputForm.vue'

export default {
  name: 'StaffInputForm',

  components: {
    FullNameInputForm,
    SelectBoxForm,
    BaseRadioButton,
    EmailInputForm,
    BaseCheckBox,
    HintBalloon,
    ColorInputForm
  },

  props: {
    staff: {
      firstName: { type: String },
      lastName: { type: String },
      jobId: { type: Number },
      shiftPatternId: { type: Number },
      loginFlg: { type: Number },
      email: { type: String },
      color: { type: String }
    },
    staffWorkingDatesFlg: { type: Boolean, default: false },
    unchangableMailFlg: { type: Boolean, default: false }
  },
  data() {
    return {
      colorDescription: `このスタッフを設定した予約に適用される色です。
（注）予約の色は「表示設定」の「予約の色の優先順位」をもとに決まります。`
    }
  },
  computed: {
    ...mapGetters({
      jobs: 'master/getJobs',
      loginStaffId: 'auth/loginStaffId',
      adminFlg: 'auth/adminFlg',
      shiftPatterns: 'shiftPatterns/selectDataZero'
    }),
    checkLoginFlg() {
      return this.staff.loginFlg === 1 ? true : false
    },
    radioButtonData() {
      const radioButtonData = [
        { id: 1, eachValue: 1, labelName: '有効' },
        { id: 2, eachValue: 0, labelName: '無効' }
      ]
      if (this.adminFlg === 1 && this.loginStaffId === this.staff.id) {
        radioButtonData.pop()
      }
      return radioButtonData
    },
    isStaffNewPage() {
      return this.$route.path.includes('new') ? true : false
    }
  },

  methods: {
    inputLastName(value) {
      this.$emit('input-last-name', value)
    },
    inputFirstName(value) {
      this.$emit('input-first-name', value)
    },
    toggleStaffWorkingDatesFlg() {
      this.$emit('toggle-staff-working-dates-flg')
    }
  }
}
</script>

<style lang="scss" scoped>
.staff-input-form {
  width: 600px;
  > .row {
    margin-bottom: 30px;
    &.check-box {
      display: flex;
      align-items: center;
    }
    > .heading {
      display: flex;
      align-items: center;
      width: 40%;
      font-size: 15px;
    }
  }
  > .login-setting {
    height: 33px;
    display: flex;
    align-items: center;
    > .heading {
      width: 40%;
      font-size: 15px;
    }
    > .radio-button {
      display: flex;
      font-size: 13px;
    }
  }
}
</style>
