<template>
  <div class="staff-show">
    <base-back-link class="base-back-link" @click="backStaffsSetting" />
    <h2>スタッフ詳細</h2>
    <div class="button-wrapper">
      <base-button-small-white
        @click="pushToStaffEdit"
        v-if="
          adminFlg === 1 ||
            loginStaffId === id ||
            (supportFlg === 1 && lookOnlyFlg === 0)
        "
        >編集</base-button-small-white
      >
      <base-button-small-red
        class="button"
        @click="alertOpen"
        v-if="
          (loginStaffId !== id && adminFlg === 1) ||
            (supportFlg === 1 && lookOnlyFlg === 0)
        "
        >削除</base-button-small-red
      >
      <announce-popup
        v-if="alertFlg"
        @cancel="alertClose"
        @decision="deleteStaff"
        @close="closePopup"
        :title="title"
        :buttons="buttons"
        :disabled="waitFlg"
        :type="type"
        >{{ popupMessage }}</announce-popup
      >
    </div>
    <h3>基本設定</h3>
    <div class="info-wrapper">
      <div class="image">
        <BaseNoPhoto v-if="displayStaff.image === null" />
        <img
          v-else
          :src="displayStaff.image"
          width="110px"
          height="110px"
          alt="display-staff-image"
        />
      </div>
      <staff-show-table :displayStaff="displayStaff" />
    </div>
  </div>
</template>

<script>
import BaseBackLink from '@/components/parts/atoms/BaseBackLink'
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import BaseButtonSmallRed from '@/components/parts/atoms/BaseButtonSmallRed'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import BaseNoPhoto from '@/components/parts/atoms/BaseNoPhoto'
import StaffShowTable from '@/components/parts/molecules/StaffShowTable'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'StaffShow',

  components: {
    BaseBackLink,
    BaseButtonSmallWhite,
    BaseButtonSmallRed,
    AnnouncePopup,
    BaseNoPhoto,
    StaffShowTable
  },

  props: {
    id: { type: Number }
  },

  data() {
    return {
      alertFlg: false,
      waitFlg: false,
      title: '注意',
      buttons: ['削除しない', '削除する'],
      type: 'alert',
      popupMessage: '削除してもよろしいですか？'
    }
  },

  computed: {
    ...mapGetters({
      getStaff: 'staffs/getDataById',
      getMasterDatum: 'master/getDataById',
      getShiftPattern: 'shiftPatterns/getDataById',
      loginStaffId: 'auth/loginStaffId',
      adminFlg: 'auth/adminFlg',
      lookOnlyFlg: 'auth/lookOnlyFlg',
      supportFlg: 'auth/supportFlg'
    }),
    displayStaff() {
      const staff = this.getStaff(this.id)
      if (staff) {
        const admin = staff.adminFlg ? '（管理者）' : ''
        const diffMinutes = moment().diff(
          moment(staff.authenticationStartTime),
          'hours'
        )
        const loginFlg =
          diffMinutes < 24 ? '認証中' : staff.loginFlg === 0 ? '無効' : '有効'
        const email =
          diffMinutes < 24 ? `${staff.changeMail}（認証中）` : staff.email
        return {
          fullName: `${staff.lastName} ${staff.firstName} ${admin}`.trim(),
          jobName: this.getMasterDatum('jobs', staff.jobId).name,
          shiftPatternName: staff.shiftPatternId
            ? this.getShiftPattern(staff.shiftPatternId).name
            : '',
          loginFlg,
          email,
          image: staff.image,
          color: staff.color
        }
      } else {
        return {}
      }
    }
  },

  methods: {
    initializeAlert() {
      this.alertFlg = false
      this.title = '注意'
      this.buttons = ['削除しない', '削除する']
      this.type = 'alert'
      this.popupMessage = '削除してもよろしいですか？'
    },
    backStaffsSetting() {
      this.$router.push({ path: '/settings/staffs' })
    },
    pushToStaffEdit() {
      this.$router.push({ path: `/settings/staffs/${this.id}/edit` })
    },
    alertOpen() {
      this.initializeAlert()
      this.alertFlg = true
    },
    alertClose() {
      this.alertFlg = false
    },
    async deleteStaff() {
      this.waitFlg = true
      const res = await this.$store.dispatch('staffs/delete', this.id)
      if (res === 'success') {
        this.type = 'success'
        this.title = '完了'
        this.buttons = ['閉じる']
        this.popupMessage = '削除しました'
      } else {
        this.type = 'failure'
        this.title = '失敗'
        this.buttons = ['閉じる']
        this.popupMessage =
          res === 'used in reservationPurpose'
            ? '来院目的で使用されているため削除できません'
            : res === 'used in reservationColumn'
            ? '予約列で使用されているため削除できません'
            : '削除に失敗しました'
      }
      this.waitFlg = false
    },
    closePopup() {
      this.alertFlg = false
      this.$router.push({ path: '/settings/staffs' })
    }
  }
}
</script>

<style lang="scss" scoped>
.staff-show {
  > .base-back-link {
    margin-left: -26px;
  }
  > h2 {
    font-size: 20px;
    font-weight: bold;
    margin-top: 20px;
    border-bottom: 1px solid #{$light-grey};
    padding-bottom: 20px;
  }
  > .button-wrapper {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    > .button {
      margin-left: 20px;
    }
  }
  > h3 {
    font-size: 15px;
    font-weight: bold;
    margin-top: 16px;
    border-bottom: 1px solid #{$light-grey};
    padding-bottom: 20px;
  }
  > .info-wrapper {
    display: flex;
    margin-bottom: 14px;
    > .image {
      height: 110px;
      width: 110px;
      margin: 20px 30px 0 0;
      > img {
        width: 110px;
        height: 110px;
        object-fit: cover;
        border-radius: 50%;
      }
    }
  }
}
</style>
