var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"staff-show"},[_c('base-back-link',{staticClass:"base-back-link",on:{"click":_vm.backStaffsSetting}}),_c('h2',[_vm._v("スタッフ詳細")]),_c('div',{staticClass:"button-wrapper"},[(
        _vm.adminFlg === 1 ||
          _vm.loginStaffId === _vm.id ||
          (_vm.supportFlg === 1 && _vm.lookOnlyFlg === 0)
      )?_c('base-button-small-white',{on:{"click":_vm.pushToStaffEdit}},[_vm._v("編集")]):_vm._e(),(
        (_vm.loginStaffId !== _vm.id && _vm.adminFlg === 1) ||
          (_vm.supportFlg === 1 && _vm.lookOnlyFlg === 0)
      )?_c('base-button-small-red',{staticClass:"button",on:{"click":_vm.alertOpen}},[_vm._v("削除")]):_vm._e(),(_vm.alertFlg)?_c('announce-popup',{attrs:{"title":_vm.title,"buttons":_vm.buttons,"disabled":_vm.waitFlg,"type":_vm.type},on:{"cancel":_vm.alertClose,"decision":_vm.deleteStaff,"close":_vm.closePopup}},[_vm._v(_vm._s(_vm.popupMessage))]):_vm._e()],1),_c('h3',[_vm._v("基本設定")]),_c('div',{staticClass:"info-wrapper"},[_c('div',{staticClass:"image"},[(_vm.displayStaff.image === null)?_c('BaseNoPhoto'):_c('img',{attrs:{"src":_vm.displayStaff.image,"width":"110px","height":"110px","alt":"display-staff-image"}})],1),_c('staff-show-table',{attrs:{"displayStaff":_vm.displayStaff}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }