<template>
  <div class="staff-edit">
    <validation-observer v-slot="{ invalid }" class="observer">
      <div class="setting-title">スタッフ編集</div>
      <div class="sub-title">基本項目</div>
      <div class="input-wrapper">
        <base-image-uploader
          class="img"
          :image="staff.image"
          @input="inputStaffImage"
          @delete="deleteStaffImage"
          @uploadingImg="setUploadingImg"
        />
        <staff-input-form
          class="input-form"
          :staff="staff"
          :unchangableMailFlg="unchangableMailFlg"
          @input-last-name="inputLastName"
          @input-first-name="inputFirstName"
          @input-job-id="inputJobId"
          @input-shift-pattern-id="inputShiftPatternId"
          @input-login-flg="inputLoginFlg"
          @input-email="inputEmail"
        />
      </div>
      <div class="button-wrapper">
        <base-button-medium-white class="button" @click="backStaffShow"
          >戻る</base-button-medium-white
        >
        <base-button-medium-orange
          class="button"
          v-if="lookOnlyFlg === 0"
          @click="confirmPopup"
          :disabled="invalid || waitFlg || uploadingImg"
          >登録</base-button-medium-orange
        >
      </div>
      <unsaved-leave-popup />
      <announce-popup
        v-if="alertFlg"
        @close="closePopup"
        @cancel="closePopup"
        @decision="updateStaff"
        :title="title"
        :buttons="buttons"
        :type="type"
        >{{ popupMessage }}</announce-popup
      >
    </validation-observer>
  </div>
</template>

<script>
import BaseImageUploader from '@/components/parts/atoms/BaseImageUploader'
import StaffInputForm from '@/components/parts/organisms/StaffInputForm'
import BaseButtonMediumWhite from '@/components/parts/atoms/BaseButtonMediumWhite'
import BaseButtonMediumOrange from '@/components/parts/atoms/BaseButtonMediumOrange'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import CheckInputDifference from '@/components/mixins/CheckInputDifference'
import { ValidationObserver } from 'vee-validate'
import { mapGetters } from 'vuex'
import _ from 'lodash'

export default {
  name: 'StaffEdit',

  components: {
    BaseImageUploader,
    StaffInputForm,
    BaseButtonMediumWhite,
    BaseButtonMediumOrange,
    AnnouncePopup,
    ValidationObserver,
    UnsavedLeavePopup
  },

  mixins: [CheckInputDifference],

  props: {
    id: { type: Number }
  },

  data() {
    return {
      initialStaff: {},
      staff: {},
      alertFlg: false,
      waitFlg: false,
      title: '',
      buttons: ['閉じる'],
      type: '',
      popupMessage: '',
      unchangableMailFlg: false,
      noDataFlg: false,
      uploadingImg: false
    }
  },

  computed: {
    ...mapGetters({
      loginStaffId: 'auth/loginStaffId',
      adminFlg: 'auth/adminFlg',
      lookOnlyFlg: 'auth/lookOnlyFlg',
      getStaff: 'staffs/getDataById'
    })
  },

  created() {
    const staff = _.cloneDeep(this.getStaff(this.id))
    this.staff = staff
    this.initialStaff = _.cloneDeep(staff)
    this.unchangableMailFlg =
      this.adminFlg === 1 &&
      this.staff.id !== this.loginStaffId &&
      this.staff.email !== ''
        ? true
        : false
    this.mixinInputData = this.staff
    this.mixinSetInitialData()
  },

  methods: {
    inputStaffImage(image) {
      this.staff.image = image
    },
    deleteStaffImage() {
      this.staff.image = null
    },
    inputLastName(value) {
      this.staff.lastName = value
    },
    inputFirstName(value) {
      this.staff.firstName = value
    },
    inputJobId(value) {
      this.staff.jobId = value
    },
    inputShiftPatternId(value) {
      this.staff.shiftPatternId = value
    },
    inputLoginFlg(value) {
      this.staff.loginFlg = value
    },
    inputEmail(value) {
      this.staff.email = value
    },
    backStaffShow() {
      this.$router.push({ name: 'staff-show' })
    },
    closePopup() {
      this.alertFlg = false
      if (this.type === 'success') {
        this.$store.dispatch('petorelu/okLeave')
        const loginStaffId = this.$store.getters['auth/loginStaffId']
        if (this.staff.id === loginStaffId && this.staff.loginFlg === 0) {
          this.$router.push({ name: 'login' })
        } else if (
          this.initialStaff.loginFlg === 0 &&
          this.staff.loginFlg === 1 &&
          this.staff.id === loginStaffId
        ) {
          this.$router.push({ name: 'password-reset-send-mail' })
        } else {
          this.backStaffShow()
        }
      } else {
        if (this.noDataFlg) {
          this.$store.dispatch('petorelu/okLeave')
          this.backStaffs()
        }
        this.title = ''
        this.buttons = ['閉じる']
        this.type = ''
        this.popupMessage = ''
      }
    },
    async confirmPopup() {
      if (this.initialStaff.loginFlg === 1 && this.staff.loginFlg === 0) {
        this.title = '注意'
        this.type = 'alert'
        this.popupMessage =
          'ログイン設定を無効にして登録をするとログインできなくなりますがよろしいですか？'
        this.buttons = ['取り消す', '登録']
        this.alertFlg = true
      } else {
        await this.updateStaff()
      }
    },
    async updateStaff() {
      this.waitFlg = true
      const result = await this.$store.dispatch(
        'staffs/updateStaff',
        this.staff
      )
      if (result === 'success') {
        this.type = 'success'
        this.title = '完了'
        this.popupMessage = '編集しました。'
        this.buttons = ['閉じる']
        this.mixinSetInitialData()
        if (
          this.initialStaff.email !== this.staff.email &&
          this.initialStaff.loginFlg === 1 &&
          this.staff.loginFlg === 1
        ) {
          this.popupMessage =
            this.popupMessage +
            `\n新しいメールアドレスに認証メールを送信しましたのでご確認下さい。`
        } else if (
          this.initialStaff.loginFlg === 0 &&
          this.staff.loginFlg === 1
        ) {
          this.popupMessage =
            this.popupMessage +
            `\nメールアドレスに認証メールを送信しましたのでご確認下さい。\nメールから手続きした段階でログイン設定が有効になります。\nログイン設定を有効にした後パスワードを発行して下さい。`
        }
      } else {
        this.type = 'failure'
        this.title = '失敗'
        this.buttons = ['閉じる']
        if (result === 'staff' || result === 'no data in clinic') {
          this.popupMessage =
            '編集に失敗しました。\n編集中のスタッフは既に削除されています。'
          this.noDataFlg = true
        } else if (result === 'email already used') {
          this.popupMessage = 'このメールアドレスは医院内で既に使用されています'
        } else if (result === 'email and password already used') {
          this.popupMessage = `同じメールアドレスとパスワードの組み合わせのスタッフが既に存在します。\nパスワードの再設定画面でパスワードを変更してから\nこの画面でメールアドレスの変更をして下さい。`
        } else if (result === 'shiftPattern') {
          this.staff = _.cloneDeep(this.initialStaff)
          this.popupMessage = `既に削除された週シフトが使用されています\n最新データに更新しますので再編集をお願いします`
        } else {
          this.popupMessage = '編集に失敗しました'
        }
      }
      this.waitFlg = false
      this.alertFlg = true
    },
    backStaffs() {
      this.$router.push({ path: '/settings/staffs' })
    },
    setUploadingImg(uploadingImgState) {
      this.uploadingImg = uploadingImgState
    }
  }
}
</script>

<style lang="scss" scoped>
.staff-edit {
  > .observer {
    > .setting-title {
      font-size: 20px;
      font-weight: bold;
      border-bottom: 1px solid #{$light-grey};
      padding-bottom: 20px;
    }
    > .sub-title {
      font-size: 15px;
      font-weight: bold;
      margin-top: 20px;
      border-bottom: 1px solid #{$light-grey};
      padding-bottom: 20px;
    }
    > .input-wrapper {
      display: flex;
      margin-top: 30px;
      > .input-form {
        margin-left: 30px;
      }
    }
    > .button-wrapper {
      display: flex;
      justify-content: center;
      margin: 60px 0;
      > .button {
        margin: 0 16px;
      }
    }
  }
}
</style>
