<template>
  <div class="staff-show-table">
    <table>
      <tr>
        <th>スタッフ名</th>
        <td data-e2e="staff-name">{{ displayStaff.fullName }}</td>
      </tr>
      <tr>
        <th>職種</th>
        <td>{{ displayStaff.jobName }}</td>
      </tr>
      <tr>
        <th>週シフト</th>
        <td>{{ displayStaff.shiftPatternName }}</td>
      </tr>
      <tr>
        <th>ログイン設定</th>
        <td>{{ displayStaff.loginFlg }}</td>
      </tr>
      <tr>
        <th>メールアドレス</th>
        <td>{{ displayStaff.email }}</td>
      </tr>
      <tr>
        <th>カラー</th>
        <td>
          <div class="color-item">
            <div
              class="color-plate"
              :style="{ backgroundColor: displayStaff.color }"
            />
            <div class="text">
              {{ displayStaff.color }}
            </div>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: 'StaffShowTable',

  props: {
    displayStaff: {
      fullName: { type: String },
      jobName: { type: String },
      shiftPatternName: { type: String },
      loginFlg: { type: String },
      email: { type: String }
    }
  }
}
</script>

<style lang="scss" scoped>
.staff-show-table {
  > table {
    width: 600px;
    > tr {
      height: 52px;
      > th {
        width: 40%;
        min-width: 240px;
        font-size: 15px;
        font-weight: normal;
      }
      > td {
        width: 60%;
        max-width: 360px;
        font-size: 13px;
        word-wrap: break-word;
        > .color-item {
          display: flex;
          > .color-plate {
            box-sizing: border-box;
            width: 22px;
            height: 22px;
            margin-right: 6px;
            border: solid 1px black;
            border-radius: 4px;
          }
        }
      }
    }
  }
}
</style>
