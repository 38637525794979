<template>
  <div class="staffs">
    <div class="title">スタッフ</div>
    <div class="content">
      <div class="area buttons" v-if="!mixinSortModeFlg">
        <base-button-register
          class="button"
          v-if="adminFlg === 1 || (supportFlg === 1 && lookOnlyFlg === 0)"
          :text="'スタッフ登録'"
          @click="openNewPage"
        />
        <base-sort-button
          class="button"
          v-if="lookOnlyFlg === 0"
          @click="setSortMode"
        />
      </div>
      <div class="area buttons" v-else>
        <base-button-small-white class="button" @click="cancelSortMode"
          >キャンセル</base-button-small-white
        >
        <base-button-small-orange
          class="button"
          v-if="lookOnlyFlg === 0"
          :disabled="waitFlg"
          @click="updateOrder"
          >並び順登録</base-button-small-orange
        >
      </div>
      <div class="area search">
        <div class="heading">スタッフ検索</div>
        <search-area
          :textBoxLabel="'検索単語'"
          :textValue="searchWord"
          :placeholder="'スタッフ名'"
          :selectBoxLabel="'職種'"
          :selectValue="searchJobId"
          :selectBoxData="selectJobs"
          :disabled="mixinSortModeFlg"
          @input="inputSearchWord"
          @select="inputSearchJobId"
        />
      </div>
      <div class="area list">
        <div class="heading">
          スタッフ一覧<span v-if="mixinSortModeFlg">{{
            mixinSortModeMessage
          }}</span>
        </div>
        <list-table
          :selectBoxFlg="true"
          :headerData="headers"
          :propertyData="properties"
          :bodyData="displayStaffs"
          :sortModeFlg="mixinSortModeFlg"
          :headerItemStyleData="styles"
          :bodyItemStyleData="styles"
          @click="openShowPage"
          @order="changeOrder"
        />
      </div>
    </div>
    <announce-popup
      v-if="mixinSortPopup.showFlg"
      :type="mixinSortPopup.type"
      :title="mixinSortPopup.title"
      :buttons="mixinSortPopup.buttons"
      @close="mixinCloseSortPopup"
      >{{ mixinSortPopup.message }}</announce-popup
    >
    <unsaved-leave-popup />
  </div>
</template>

<script>
import BaseButtonRegister from '@/components/parts/atoms/BaseButtonRegister'
import BaseSortButton from '@/components/parts/atoms/BaseSortButton'
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import BaseButtonSmallOrange from '@/components/parts/atoms/BaseButtonSmallOrange'
import SearchArea from '@/components/parts/molecules/SearchArea'
import ListTable from '@/components/parts/organisms/ListTable.vue'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import ApiOrder from '@/components/mixins/ApiOrder'
import CheckInputDifference from '@/components/mixins/CheckInputDifference'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'Staffs',

  components: {
    BaseButtonRegister,
    BaseSortButton,
    BaseButtonSmallWhite,
    BaseButtonSmallOrange,
    SearchArea,
    ListTable,
    AnnouncePopup,
    UnsavedLeavePopup
  },

  mixins: [ApiOrder, CheckInputDifference],

  data() {
    return {
      searchWord: '',
      searchJobId: 0,
      selectJobs: [],
      headers: ['標準', 'スタッフ名', '職種', '週シフト', 'ログイン設定'],
      properties: ['order', 'fullName', 'jobId', 'shiftName', 'loginStatus'],
      styles: [
        { width: '0%' },
        { width: '35%' },
        { width: '20%' },
        { width: '25%' },
        { width: '20%' }
      ],
      waitFlg: false
    }
  },

  computed: {
    ...mapGetters({
      staffs: 'staffs/getStaffs',
      jobs: 'master/getJobs',
      getShiftPattern: 'shiftPatterns/getDataById',
      adminFlg: 'auth/adminFlg',
      lookOnlyFlg: 'auth/lookOnlyFlg',
      supportFlg: 'auth/supportFlg'
    }),
    displayStaffs() {
      const data = this.mixinSortModeFlg ? this.mixinInputData : this.staffs
      return data
        .map(staff => {
          const admin = staff.adminFlg === 1 ? '（管理者）' : ''
          const shiftPattern = this.getShiftPattern(staff.shiftPatternId)
          const elapsedTime = moment().diff(
            moment(staff.authenticationStartTime),
            'hours'
          )
          const loginStatus =
            elapsedTime < 24 ? '認証中' : staff.loginFlg === 0 ? '無効' : '有効'
          return {
            ...staff,
            fullName: `${admin} ${staff.lastName} ${staff.firstName}`,
            shiftName: shiftPattern ? shiftPattern.name : '',
            loginStatus
          }
        })
        .filter(
          v =>
            v.fullName
              .toLowerCase()
              .replace(/\s+/g, '')
              .includes(this.searchWord.toLowerCase()) &&
            (this.searchJobId === 0 ? true : v.jobId === this.searchJobId)
        )
    }
  },

  watch: {
    mixinSortModeFlg: function() {
      this.setData()
    }
  },

  created() {
    this.selectJobs = [{ id: 0, name: '全て' }].concat(this.jobs)
    this.setData()
  },

  methods: {
    setData() {
      this.mixinInputData = this.staffs
      this.mixinSetInitialData()
    },
    inputSearchWord(val) {
      this.searchWord = val
    },
    inputSearchJobId(val) {
      this.searchJobId = val
    },
    openNewPage() {
      this.$router.push({ path: '/settings/staffs/new' })
    },
    openShowPage(id) {
      this.$router.push({ path: `staffs/${id}` })
    },
    setSortMode() {
      this.mixinSortModeFlg = true
      this.searchWord = ''
      this.searchJobId = 0
    },
    cancelSortMode() {
      this.mixinSortModeFlg = false
    },
    changeOrder(data) {
      this.mixinInputData = data.map(v1 =>
        this.mixinInputData.find(v2 => v2.id === v1.id)
      )
    },
    async updateOrder() {
      this.waitFlg = true
      await this.mixinUpdateOrder('staffs', this.mixinInputData)
      if (this.mixinSortSucceeded) this.mixinSetInitialData()
      this.waitFlg = false
    }
  }
}
</script>

<style lang="scss" scoped>
.staffs {
  > .title {
    padding-bottom: 20px;
    font-size: 20px;
    font-weight: bold;
    border-bottom: 1px solid #{$light-grey};
  }
  > .content {
    margin-top: 20px;
    margin-bottom: 24px;
    > .area {
      > .heading {
        margin-bottom: 16px;
        font-size: 15px;
        font-weight: bold;
      }
      &.buttons {
        display: flex;
        justify-content: flex-end;
        > .button {
          margin-left: 20px;
        }
      }
      &.search {
        margin-top: 16px;
      }
      &.list {
        margin-top: 26px;
        ::v-deep .list-table .table-body {
          max-height: 560px;
        }
      }
    }
  }
}
</style>
