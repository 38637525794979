<template>
  <div class="staff-new">
    <validation-observer v-slot="{ invalid }" class="observer">
      <div class="setting-title">スタッフ登録</div>
      <div class="sub-title">基本項目</div>
      <div class="input-wrapper">
        <base-image-uploader
          class="img"
          :image="staff.image"
          @input="inputStaffImage"
          @delete="deleteStaffImage"
          @uploadingImg="setUploadingImg"
        />
        <staff-input-form
          class="input-form"
          :staff="staff"
          :staff-working-dates-flg="staffWorkingDatesFlg"
          @input-last-name="inputLastName"
          @input-first-name="inputFirstName"
          @toggle-staff-working-dates-flg="toggleStaffWorkingDatesFlg"
        />
      </div>
      <div class="button-wrapper">
        <base-button-medium-white class="button" @click="backStaffsSetting"
          >戻る</base-button-medium-white
        >
        <base-button-medium-orange
          class="button"
          v-if="lookOnlyFlg === 0"
          @click="createStaff"
          :disabled="invalid || waitFlg || uploadingImg"
          >登録</base-button-medium-orange
        >
      </div>
      <unsaved-leave-popup />
      <announce-popup
        v-if="alertFlg"
        @close="closePopup"
        :title="title"
        :buttons="buttons"
        :type="type"
        >{{ popupMessage }}</announce-popup
      >
    </validation-observer>
  </div>
</template>

<script>
import BaseImageUploader from '@/components/parts/atoms/BaseImageUploader'
import StaffInputForm from '@/components/parts/organisms/StaffInputForm'
import BaseButtonMediumWhite from '@/components/parts/atoms/BaseButtonMediumWhite'
import BaseButtonMediumOrange from '@/components/parts/atoms/BaseButtonMediumOrange'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import { ValidationObserver } from 'vee-validate'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import CheckInputDifference from '@/components/mixins/CheckInputDifference'
import { mapGetters } from 'vuex'

export default {
  name: 'StaffNew',

  components: {
    BaseImageUploader,
    StaffInputForm,
    BaseButtonMediumWhite,
    BaseButtonMediumOrange,
    AnnouncePopup,
    ValidationObserver,
    UnsavedLeavePopup
  },

  mixins: [CheckInputDifference],

  data() {
    return {
      staff: {
        firstName: '',
        lastName: '',
        email: '',
        loginFlg: 0,
        jobId: '',
        shiftPatternId: 0,
        image: null,
        color: '#ef6c00'
      },
      staffWorkingDatesFlg: false,
      alertFlg: false,
      waitFlg: false,
      title: '',
      buttons: ['閉じる'],
      type: '',
      popupMessage: '',
      uploadingImg: false
    }
  },

  computed: {
    ...mapGetters({
      lookOnlyFlg: 'auth/lookOnlyFlg'
    })
  },

  created() {
    this.mixinInputData = this.staff
    this.mixinSetInitialData()
  },

  methods: {
    inputStaffImage(image) {
      this.staff.image = image
    },
    deleteStaffImage() {
      this.staff.image = null
    },
    inputLastName(value) {
      this.staff.lastName = value
    },
    inputFirstName(value) {
      this.staff.firstName = value
    },
    toggleStaffWorkingDatesFlg() {
      this.staffWorkingDatesFlg = !this.staffWorkingDatesFlg
    },
    backStaffsSetting() {
      this.$router.push({ path: '/settings/staffs' })
    },
    closePopup() {
      this.alertFlg = false
      if (this.title === '完了') {
        this.$store.dispatch('petorelu/okLeave')
        this.$router.push({ path: '/settings/staffs' })
      }
    },
    async createStaff() {
      this.waitFlg = true
      const obj = {
        staff: this.staff,
        staffWorkingDatesFlg: this.staffWorkingDatesFlg
      }
      const res = await this.$store.dispatch('staffs/create', obj)
      if (res === 'success') {
        this.title = '完了'
        this.type = 'success'
        this.popupMessage = '新規登録しました。'
        this.mixinSetInitialData()
        if (this.staff.loginFlg === 1) {
          this.popupMessage +=
            '\n登録したメールアドレスにメールを送信しました。\nログインの際に使用するパスワードが記載されているのでご確認下さい。'
        }
      } else {
        this.title = '失敗'
        this.type = 'failure'
        this.popupMessage =
          res === 'email already used'
            ? 'このメールアドレスは既に使用されています'
            : res === 'shiftPattern'
            ? '既に削除された週シフトが使用されています\n最新データに更新しますので再編集をお願いします'
            : '登録に失敗しました'
      }
      this.waitFlg = false
      this.alertFlg = true
    },
    setUploadingImg(uploadingImgState) {
      this.uploadingImg = uploadingImgState
    }
  }
}
</script>

<style lang="scss" scoped>
.staff-new {
  > .observer {
    > .setting-title {
      font-size: 20px;
      font-weight: bold;
      border-bottom: 1px solid #{$light-grey};
      padding-bottom: 20px;
    }
    > .sub-title {
      font-size: 15px;
      font-weight: bold;
      margin-top: 20px;
      border-bottom: 1px solid #{$light-grey};
      padding-bottom: 20px;
    }
    > .input-wrapper {
      display: flex;
      margin-top: 30px;
      > .input-form {
        margin-left: 30px;
      }
    }
    > .button-wrapper {
      display: flex;
      justify-content: center;
      margin: 60px 0;
      > .button {
        margin: 0 16px;
      }
    }
  }
}
</style>
